@import "styles/variable";

.lobby {
  position: relative;
  margin-bottom: 72px;
  .pic {
    position: relative;
    left: 0;
    transition: 1s;
    opacity: 1;
    &.start {
      opacity: 0;
      left: -100px;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .text {
    position: absolute;
    left: 50%;
    top: -43px;
    max-width: 640px;
    background: $color-blue-dark;
    padding: 80px 60px 60px;
    color: #fff;
    opacity: 1;
    transition: 1s;
    &.start {
      opacity: 0;
      left: 60%;
    }
  }

  .button {
    margin-top: 140px;
  }
}

@media all and (max-width: 1168px) {
  .lobby {
    .text {
      padding: 40px;
    }
    .button {
      margin-top: 30px;
    }
  }
}

@media all and (max-width: 940px) {
  .lobby {
    .text {
      position: relative;
      left: 0;
      width: 80%;
      margin: 0 auto;

      &.start {
        left: -80px;
      }
    }
  }
}

@media all and (max-width: 700px) {
  .lobby {
    margin-bottom: 30px;
    .text {
      padding: 20px;
      top: 0;
      width: 100%;
    }
    .button {
      font-size: 14px;
      padding: 16px;
    }
  }
}

@media all and (max-width: 420px) {
  .lobby .button {
    width: 100%;
    text-align: center;
  }
}